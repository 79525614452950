import { FETCH_CLIENTS, CLIENT_PROP_CHANGE, SEARCH_CLIENTS } from '../actionTypes/ClientActions';
import firebase from 'firebase';

export const fetchClients = () => {
    return async (dispatch) => {
        firebase.firestore().collection('clients')
        .orderBy('name')
        .onSnapshot(snap => {
            console.log(snap.docs);
            dispatch({ type: FETCH_CLIENTS, payload: snap.docs });
        });
    }
} 

export const addClient = (name, email) => {
    return async () => {
        var date = new Date();
        const created = date.getTime();
        firebase.firestore().collection('clients').add({
            name,
            email,
            created: created,
            updated: created
        });
    }
}

export const updateClient = (name, email, id) => {
    return async () => {
        var date = new Date();
        const created = date.getTime();
        await firebase.firestore().doc(`clients/${id}`).update({
            name,
            email,
            created: created
        })
        const snap = await firebase.firestore().collection('schedules').where('clientId', '==', id)
        .get()
        .then();
        const schedules = snap.docs;
        for (let i = 0; i < schedules.length; i++) {
            firebase.firestore().doc(`schedules/${schedules[i].id}`).update({
                clientEmail: email
            });
        }

    }
}

export const deleteClient = (id) => {
    return async () => {
        firebase.firestore().doc(`clients/${id}`).delete();
    }
}

export const searchClients = (search, clients) => {
    return async (dispatch) => {
        dispatch({ type: CLIENT_PROP_CHANGE, payload: { prop: 'search', value: search }});
        const results = [];
        for (let i = 0; i < clients.length; i++) {
            if (clients[i].data().name.toLowerCase().includes(search.toLowerCase())) {
                results.push(clients[i]);
            }
        }
        dispatch({ type: SEARCH_CLIENTS, payload: results });
    }
}