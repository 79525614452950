import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/EditPhase.css';
import Modal from './Modal';
import { FlatButton, MenuItem } from 'material-ui';
import Input from './Input';
import Dropdown from './Dropdown';
import { BUILD_PHASES } from '../../constants/buildPhases';
import { findEndTime, getDaysInBetween } from '../../utilities/DateUtil';
import DateInput from './DateInput';

const MIN_DAYS = 6;

class EditPhase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phase: { ...this.props.phase },
        }
    }

    actions = [
        <FlatButton 
            labelStyle={{ color: '#56ACF2' }}
            label='Save'
            onClick={() => this.save()}
        />,
        <FlatButton 
            labelStyle={{ color: 'salmon' }}
            label='Cancel'
            onClick={() => this.props.cancel()}
        />
    ]

    save = () => {
        const { phase } = this.state;
        const pack = {
            startDate: phase.startDate,
            endDate: findEndTime(phase.startDate, phase.days),
            interval: phase.interval,
            days: phase.days,
            phase: phase.phase,
            guid: phase.guid
        }
        this.props.save(pack);
    }

    getIntervalInfo = () => {
        const { startDate, days } = this.state.phase;
        const endTime = findEndTime(startDate, days);
        const differenceInDays = getDaysInBetween(startDate, endTime) - 2;
        const label = `Email Interval (${differenceInDays} DAY MAX)*`;
        return { label, max: differenceInDays };
    }

    startChange = (startDate) => {
        const phase = { ...this.state.phase };
        phase.startDate = startDate.getTime();
        this.setState({ phase })
    }

    dayChange = (days) => {
        if (days >= MIN_DAYS) {
            const phase = { ...this.state.phase };
            phase.days = days;
            this.setState({ phase })
        }
    }

    phaseChange = (phase) => {
        const phaseCopy = { ...this.state.phase };
        phaseCopy.phase = phase;
        this.setState({ phase: phaseCopy })
    }

    intervalChange = (interval) => {
        const intervalInfo = this.getIntervalInfo();
        if (interval <= intervalInfo.max && interval > 0) {
            const phaseCopy = { ...this.state.phase };
            phaseCopy.interval = interval;
            this.setState({ phase: phaseCopy })
        }
    }

    render = () => {
        const intervalInfo = this.getIntervalInfo();
        return (
            <Modal
                open={this.props.open}
                title={this.props.title}
                darkMode={this.props.darkMode}
                actions={this.actions}
            >
                <div className='EditPhase-Row'>
                    <DateInput 
                        floatingLabelText='Start Date*'
                        value={new Date(this.state.phase.startDate)}
                        darkMode={this.props.darkMode}
                        minDate={this.props.minDate}
                        onChange={this.startChange}
                    />
                    <Input 
                        type='number'
                        darkMode={this.props.darkMode}
                        floatingLabelText='# of days'
                        style={styles.marginLeft}
                        value={this.state.phase.days}
                        onChange={this.dayChange}
                    />
                </div>
                <div className='EditPhase-Row'>
                    <Dropdown
                        floatingLabelText='Build Phase*'
                        value={this.state.phase.phase}
                        darkMode={this.props.darkMode}
                        onChange={this.phaseChange}
                    >
                        {this.props.phaseTypes.map((buildPhase, index) => <MenuItem key={index} primaryText={buildPhase.name} value={buildPhase} />)}
                    </Dropdown>
                    <Input 
                        type='number'
                        style={styles.marginLeft}
                        darkMode={this.props.darkMode}
                        value={this.state.phase.interval}
                        floatingLabelText={intervalInfo.label}
                        onChange={this.intervalChange}
                    />
                </div>
            </Modal>
        )
    }
}

const styles = {
    marginLeft: {
        marginLeft: 15
    }
}

EditPhase.propTypes = {
    open: PropTypes.bool,   
    title: PropTypes.string,
    darkMode: PropTypes.bool,
    phase: PropTypes.object,
    save: PropTypes.func,
    cancel: PropTypes.func,
    minDate: PropTypes.object,
    phaseTypes: PropTypes.array
}

EditPhase.defaultProps = {
    open: false,
    title: '',
    darkMode: false,
    phase: {},
    save: () => {},
    cancel: () => {},
    minDate: new Date(),
    phaseTypes: []
}

export default EditPhase;