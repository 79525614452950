import firebase from 'firebase';
import { FETCH_SCHEDULES, SET_SCHEDULE_TO_EDIT, EMAIL_PROP_CHANGE } from '../actionTypes/EmailActions';

export const saveSchedule = (client, phases) => {
    return async () => {
        await firebase.firestore().collection('schedules').add({
            clientId: client.id,
            clientName: client.data().name,
            clientEmail: client.data().email,
            phases: phases
        });
    }
}

export const fetchSchedules = () => {
    return async (dispatch) => {
        firebase.firestore().collection('schedules').onSnapshot(snap => {
            dispatch({ type: FETCH_SCHEDULES, payload: snap.docs })
        })
    }
}

export const deleteSchedule = (scheduleId) => {
    return async () => {
        await firebase.firestore().collection('schedules').doc(scheduleId).delete().then();
    }
}

export const setScheduleToEdit = (scheduleSnap) => {
    return { type: SET_SCHEDULE_TO_EDIT, payload: scheduleSnap };
}

export const searchSchedules = (search, schedules) => {
    return async (dispatch) => {
        dispatch({ type: EMAIL_PROP_CHANGE, payload: { prop: 'search', value: search }});
        const results = [];
        for (let i = 0; i < schedules.length; i++) {
            if (schedules[i].data().clientName.toLowerCase().includes(search.toLowerCase())) {
                results.push(schedules[i]);
            }
        }
        dispatch({ type: EMAIL_PROP_CHANGE, payload: { prop: 'results', value: results }});
    }
}