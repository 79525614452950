import React from 'react';
import PropTypes from 'prop-types';
import './styles/Table.css';
import { ActionSearch } from 'material-ui/svg-icons';
import { RaisedButton, CircularProgress } from 'material-ui';
import { ContentAdd } from 'material-ui/svg-icons';
import { CommunicationEmail } from 'material-ui/svg-icons';

const Table = ({ placeholder, children, search, onChange, add, headers, showEmpty, showInfo, infoLabel, emptyMessage, addLabel, errorText, loadingSend, hideAdd, darkMode, hideSearch, showSend, send }) => {

    const change = (event) => {
        onChange(event.target.value);
    }

    const decideSend = () => {
        if (loadingSend) {
            return (
                <div className='Table-SendingContainer'>
                    <CircularProgress color='#56ACF2'  />
                </div>
            )
        }
        return (
            <RaisedButton 
                label='send email'
                backgroundColor='#56ACF2'
                labelColor='#fff'
                onClick={send}
                icon={<CommunicationEmail />}
            />
        )
    }

    const renderSearchRow = () => {
        return (
            <div className='Table-SearchRow'>
                <div className='Table-SearchInputContainer'>
                    { 
                        !hideSearch && <ActionSearch 
                        style={styles.searchIcon}
                        color={darkMode ? '#fff' : '#50473A'}
                        />
                    }
                    {
                        !hideSearch && <input 
                            className={darkMode ? 'Table-SearchInputDark' : 'Table-SearchInput'}
                            placeholder={placeholder}
                            value={search}
                            onChange={change}
                        />
                    }

                </div>
                <div className='Table-AddContainer'>
                    { showSend && decideSend()
                    }
                    { !hideAdd && 
                        <RaisedButton 
                            label={addLabel}
                            backgroundColor='#56ACF2'
                            labelColor='#fff'
                            onClick={add}
                            icon={<ContentAdd />}
                            style={{ marginLeft: 15 }}
                        />
                    }
                </div>
            </div>
        )
    }
    
    const renderHeaders = () => {
        return (
            <div className={darkMode ? 'Table-TableHeaderDark' : 'Table-TableHeader'}>
                {headers.map(renderHeader)}
            </div>
        )
    }

    const renderHeader = (header, index) => {
        return (
            <div key={index} className='Table-HeaderCell'>
                <p>{header}</p>
            </div>
        )
    }

    const renderInfo = () => {
        if (showInfo) {
            return (
                <div className={darkMode ? 'Table-InfoContainerDark' : 'Table-InfoContainer'}>
                    <p>{infoLabel}</p>
                </div>
            )
        }
    }

    const renderEmpty = () => {
        if (errorText) {
            return (
                <div className={darkMode ? 'Table-EmptyContainerDark' : 'Table-EmptyContainer'}>
                    <p className='Table-Error'>{errorText}</p>
                </div>
            )
        }
        return (
            <div className={darkMode ? 'Table-EmptyContainerDark' : 'Table-EmptyContainer'}>
                <p>{emptyMessage}</p>
            </div>
        )
    }

    const renderList = () => {
        return (
            <div className='Table-ListColumn'>
                {children}
            </div>

        )
    }

    const renderDecideContent = () => {
        if (showEmpty) {
            return renderEmpty();
        }
        return renderList();
    }

    return (
        <div className={darkMode ? 'Table-ContainerDark' : 'Table-Container'}>
            <div className={darkMode ? 'Table-SearchColumnDark' : 'Table-SearchColumn'}>
                {renderSearchRow()}
                {renderHeaders()}
            </div>
            {renderDecideContent()}
            {renderInfo()}
        </div>
    )
}

const styles = {
    searchIcon: { 
        width: 30, 
        height: 30, 
        marginLeft: 15,
        marginRight: 15,
    },
    searchIconDark: { 
        width: 30, 
        height: 30, 
        marginLeft: 15,
        marginRight: 15,
    },
}

Table.propTypes = {
    placeholder: PropTypes.string,
    children: PropTypes.node,
    search: PropTypes.string,
    onChange: PropTypes.func,
    add: PropTypes.func,
    headers: PropTypes.array,
    showEmpty: PropTypes.bool,
    showInfo: PropTypes.bool,
    infoLabel: PropTypes.string,
    emptyMessage: PropTypes.string,
    addLabel: PropTypes.string,
    hideAdd: PropTypes.bool,
    hideSearch: PropTypes.bool,
    showSend: PropTypes.bool,
    send: PropTypes.func,
    loadingSend: PropTypes.bool
};

Table.defaultProps = {
    placeholder: '',
    children: null,
    search: '',
    onChange: () => {},
    add: () => {},
    headers: [],
    showEmpty: false,
    showInfo: false,
    infoLabel: '',
    emptyMessage: '',
    addLabel: '',
    hideAdd: false,
    hideSearch: false,
    showSend: false,
    send: () => {},
    loadingSend: false
}

export default Table;