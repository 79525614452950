import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Template, Table } from '../CommonComponents';

class Dash extends Component {
    render = () => {
        return (
            <Template darkMode={this.props.darkMode} title='Home'>

            </Template>
        )
    }
}

const mapStateToProps = (state) => {
    const { darkMode } = state.dark;
    return { darkMode }
}

export default connect(mapStateToProps, {})(Dash);