import React from 'react';
import PropTypes from 'prop-types'
import './styles/TableRow.css';

const TableRow = ({ index, children, darkMode }) => {

    const decideClass = () => {
        if (darkMode) {
            if (index % 2 === 0) {
                return 'TableRow-ContainerDark';
            }
            return 'TableRow-ContainerOddDark';
        }
        if (index % 2 === 0) {
            return 'TableRow-Container';
        }
        return 'TableRow-ContainerOdd';
    }

    return (
        <div className={decideClass()}>
            {children}
        </div>
    )
}

TableRow.propTypes = {
    index: PropTypes.number,
    children: PropTypes.node,
    darkMode: PropTypes.bool
};

TableRow.defaultProps = {
    index: 0,
    children: null,
    darkMode: false
}

export default TableRow;