import weekend from "material-ui/svg-icons/content/weekend";

export const MS_IN_DAY = (1000*60*60*24);
export const MS_IN_HOUR = (1000*60*60);

export const parseDateToReadable = (date) => {
    const dayInt = date.getDate();
    const monthInt = date.getMonth();
    const year = date.getFullYear();
    const month = parseMonth(monthInt);
    return `${month} ${dayInt.toString()}, ${year}`
}

export const parseTimeToReadableDate = (time) => {
    const date = new Date(time);
    const dayInt = date.getDate();
    const monthInt = date.getMonth();
    const year = date.getFullYear();
    const month = parseMonth(monthInt);
    return `${month} ${dayInt.toString()}, ${year}`
}

export const getDifferenceinDaysUNIX = (time1, time2) => {
    return getDaysInBetween(time1, time2) + 2;
}

export const getDateInBetweenTime = (time1, time2) => {
    if (time1 === time2) {
        return 0;
    }
    if (time1 > time2) {
        const difference = time1 - time2;
        return Math.floor(difference / MS_IN_DAY) - 1;
    }
    if (time2 > time1) {
        const difference = time2 - time1;
        return Math.floor(difference / MS_IN_DAY) - 1;
    }
}

export const getDifferenceInDays = (date1, date2) => {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    if (time1 == time2) {
        return 0;
    }
    if (time1 > time2) {
        const difference = time1 - time2;
        return Math.floor(difference / MS_IN_DAY) + 1;
    }
    if (time2 > time1) {
        const difference = time2 - time1;
        return Math.floor(difference / MS_IN_DAY) + 1;
    }
}

export const getDaysInBetween = (time1, time2) => {
    if (time1 + MS_IN_DAY === time2 || time2 + MS_IN_DAY === time1) {
        return 0;
    }
    if (time1 === time2) {
        return 0;
    }
    if (time1 > time2) {
        const difference = time1 - time2;
        return Math.floor(difference / MS_IN_DAY);
    }
    if (time2 > time1) {
        const difference = time2 - time1;
        return Math.floor(difference / MS_IN_DAY);
    }
}

const parseMonth = (month) => {
    switch (month) {
        case 0:
            return 'January';
        case 1:
            return 'February';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'August';
        case 8:
            return 'September';
        case 9:
            return 'October';
        case 10:
            return 'November';
        case 11:
            return 'December';
        default:
            return '';
    }
}

export const parseTimeTo12amTime = (time) => {
    const date = new Date(time);
    const dayInt = date.getDate();
    const monthInt = date.getMonth();
    const year = date.getFullYear();
    const date2 = new Date(year, monthInt, dayInt);
    return date2.getTime();
}

export const accountForWeekend = (time) => {
    const date = new Date(time);
    if (date.getDay() === 0) {
        return addTime(time, 1);
    } else if (date.getDay() === 6) {
        return addTime(time, 2);
    } else {
        return time;
    }
}

export const addTime = (time, daysToAdd) => {
    const newTime = time + (daysToAdd * MS_IN_DAY);
    const newDate = new Date(newTime);
    if (newDate.getHours() === 23) {
        return newTime + MS_IN_HOUR;
    } else if (newDate.getHours() === 1) {
        return newTime - MS_IN_HOUR;
    } else {
        return newTime;
    }
}

export const findEndTime  = (startTime, daysp) => {
    const days = parseInt(daysp);
    const weekends = findWeekends(startTime, days);
    const moreWeekends = findWeekends(startTime, days + weekends);
    const totalDays = days + moreWeekends;
    const endTime = addTime(startTime, totalDays) - MS_IN_DAY;
    const endDate = new Date(endTime);
    if (endDate.getDay() === 0) {
        return addTime(endTime, 1);
    } else if (endDate.getDay() === 6) {
        return addTime(endTime, 2);
    } else {
        return endTime;
    }

}

const findWeekends = (startTime, days) => {
    let weekends = 0;
    for (let i = 0; i < days; i++) {
        const time = addTime(startTime, i);
        const date = new Date(time);
        if (date.getDay() === 6) {
            weekends += 2;
        }
    }
    return weekends;
}