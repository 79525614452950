import { MS_IN_DAY, MS_IN_HOUR, addTime } from './DateUtil';

export const START = 'Started';
export const PROGRESS = 'In progress';
export const ALMOST_DONE = 'Almost done';
export const FINISH_LAST_PHASE_START_NEW_PHASE = 'Starting new phase';
export const DONE= 'Finished';

const FINAL_DAY = 3;

export const createEmailList = (phases) => {
    const list = [];
    for (let i = 0; i < phases.length; i++) {
        const phase = phases[i];
        list.push({
            emailTime: phase.startDate,
            endDay: phase.startDate + MS_IN_DAY,
            phase: phase.phase.name,
            status: START,
            phaseIndex: i
        });
        for (let e = addTime(phase.startDate, phase.interval); e <= addTime(phase.endDate, -1);  e = addTime(e, phase.interval)) {
            if (e >= addTime(phase.endDate, -FINAL_DAY)) {
                list.push({
                    emailTime: addTime(phase.endDate, -FINAL_DAY),
                    endDay: addTime(phase.endDate, -FINAL_DAY) + MS_IN_DAY,
                    phase: phase.phase.name,
                    status: ALMOST_DONE,
                    phaseIndex: i
                });
                break;
            } else {
                list.push({
                    emailTime: e,
                    endDay: e + MS_IN_DAY,
                    phase: phase.phase.name,
                    status: PROGRESS,
                    phaseIndex: i
                });
            }
        }
    }
    return list;
}

export const getCurrentIndexInEmailList = (emailList) => {
    const now = new Date().getTime();
    for (let i = 0; i < emailList.length; i++) {
        const email = emailList[i];
        if (i === 0 && now < email.emailTime) {
            //hasnt started yet
            return -1;
        }
        if (i === emailList.length - 1 && now > email.endDay) {
            //is done
            return -2;
        }
        if (now >= email.emailTime && now < email.endDay) {
            //schedule is active
            return i;
        } else {
            if (now > email.endDay && now < emailList[i + 1].emailTime) {
                return i + 0.5;
            }
        }
    }
}