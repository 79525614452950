import React from 'react';
import './styles/NavBar.css';
import TabNav from '../CommonComponents/TabNav';
import { CLIENTS, EMAIL, NOTIFICATIONS, HOME, ADD_EMAIL, EDIT_SCHEDULE, PHASES } from '../../constants/routes';

const NavBar = ({ currentRoute, showAlertBadge, alertCount, darkMode }) => {
    return (
        <div className='NavBar-Container'>
            <TabNav 
                type={EMAIL}
                selected={EMAIL === currentRoute || ADD_EMAIL === currentRoute || EDIT_SCHEDULE === currentRoute}
                darkMode={darkMode}
            />
            <TabNav 
                type={CLIENTS} 
                selected={CLIENTS === currentRoute}
                darkMode={darkMode}
            />
            <TabNav 
                type={NOTIFICATIONS} 
                selected={NOTIFICATIONS === currentRoute} 
                showAlertBadge={showAlertBadge}
                alertCount={alertCount}
                darkMode={darkMode}
            />
            <TabNav 
                type={PHASES}
                selected={PHASES === currentRoute}
                darkMode={darkMode}
            />
        </div>
    )
}

export default NavBar;