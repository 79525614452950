import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RaisedButton, MenuItem, FlatButton, IconButton, IconMenu, Divider } from 'material-ui';
import { saveSchedule } from '../../actions/EmailActions';
import { Template, Table, TableRow, TableCell } from '../CommonComponents';
import './styles/AddEmailSchedule.css';
import { parseTimeToReadableDate, findEndTime } from '../../utilities/DateUtil';
import { NavigationMoreVert } from 'material-ui/svg-icons';
import { ActionDelete } from 'material-ui/svg-icons';
import { ImageEdit } from 'material-ui/svg-icons';
import Modal from '../CommonComponents/Modal';
import Dropdown from '../CommonComponents/Dropdown';
import { createEmailList } from '../../utilities/EmailUtil';
import AddPhase from '../CommonComponents/AddPhase';
import EditPhase from '../CommonComponents/EditPhase';
import { EMAIL } from '../../constants/routes';

class AddEmailSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            phases: [],
            clientError: false,
            scheduleError: false,

            open: false,

            deleteOpen: false,
            deleteIndex: null,

            editOpen: false,
            editPhase: null,
            editIndex: 0,
        }
    }

    addActions = [
        <FlatButton 
            label='Save and Add'
            style={{ color: '#56ACF2' }}
            onClick={() => this.savePhaseAndAdd()}
        />,
        <FlatButton 
            label='Save'
            style={{ color: '#56ACF2' }}
            onClick={() => this.savePhase()}
        />,
        <FlatButton 
            label='Cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelPhase()}
        />
    ];

    deleteActions = [
        <FlatButton 
            label='Delete'
            style={{ color: 'salmon' }}
            onClick={() => this.deletePhase()}
        />,
        <FlatButton 
            label='Cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelDeletePhase()}
        />
    ];

    updateActions = [
        <FlatButton 
            label='Update'
            style={{ color: '#56ACF2' }}
            onClick={() => this.updatePhase()}
        />,
        <FlatButton 
            label='Cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelEditPhase()}
        />
    ];

    saveSchedule = () => {
        if (this.canSave()) {
            this.props.saveSchedule(this.state.client, this.state.phases);
            window.location.hash = `/${EMAIL}`;
        } else {
            this.triggerError();
        }

    }

    triggerError = () => {
        if (!this.state.client) {
            this.setState({ clientError: true });
        }
        if (this.state.phases.length === 0) {
            this.setState({ scheduleError: true });
        }
    }

    openPhaseDialog = () => {
        this.setState({ open: true });
    }

    openDeleteDialog = (phase, deleteIndex) => {
        this.setState({
            deleteOpen: true,
            phase,
            deleteIndex
        });
    }

    openEditDialog = (editPhase, editIndex) => {
        this.setState({
            editPhase,
            editIndex,
            editOpen: true
        });
    }

    cancelEditPhase = () => {
        this.setState({
            editPhase: null,
            editIndex: 0,
            editOpen: false
        })
    }

    deletePhase = () => {
        const { phases } = this.state;
        const phasesCopy = [...phases];
        phasesCopy.splice(this.state.deleteIndex, 1);
        this.setState({ phases: phasesCopy, deleteOpen: false, phase: null, deleteIndex: 0 })
    }

    updatePhase =  (phase) => {
        const { phases } = this.state;
        const phasesCopy = [...phases];
        phasesCopy[this.state.editIndex] = phase;
        if (this.state.editIndex < this.state.phases.length) {
            const index = this.state.editIndex + 1;
            for (let i = index; i < this.state.phases.length; i++) {
                const phase = phasesCopy[i];
                phase.startDate = phasesCopy[i - 1].endDate;
                phase.endDate = findEndTime(phase.startDate, parseInt(phase.days));
                phasesCopy[i] = phase;
            }
        }
        this.setState({ phases: phasesCopy });
        this.cancelEditPhase();
    }

    cancelDeletePhase = () => {
        this.setState({ deleteOpen: false, phase: null, deleteIndex: 0 });
    }

    canSave = () => {
        const { client, phases } = this.state;
        if (!client || phases.length === 0) {
            return false;
        }
        return true;
    }

    cancelPhase = () => {
        this.setState({ open: false })
    }

    savePhase = (phaseToAdd) => {
        this.setState({
            phases: [...this.state.phases, phaseToAdd],
            scheduleError: false
        })
        this.cancelPhase();
    }

    savePhaseAndAdd = (phaseToAdd) => {
        this.setState({
            phases: [...this.state.phases, phaseToAdd],
            scheduleError: false
        })
    }

    clientChange = (client) => {
        this.setState({ client, clientError: false });
    }

    renderClientOption = (clientSnap, index) => {
        return (
            <MenuItem primaryText={clientSnap.data().name} value={clientSnap} />
        )
    }
    
    renderTopRow = () => {
        return (
            <div className='AddEmailSchedule-TopRow'>
                <Dropdown 
                    floatingLabelText='Choose a client*'
                    style={styles.select}
                    value={this.state.client}
                    darkMode={this.props.darkMode}
                    onChange={this.clientChange}
                    errorText={this.state.clientError && 'A client is required'}
                >
                    {this.props.clients.map(this.renderClientOption)}
                </Dropdown>
            </div>
        )
    }

    renderDeleteDialog = () => {
        if (this.state.deleteOpen) {
            return (
                <Modal
                    open={this.state.deleteOpen}
                    title={`Are you sure you want to delete the ${this.state.phase.name} phase?`}
                    actions={this.deleteActions}
                    darkMode={this.props.darkMode}
                />
            )
        }

    }

    renderDialog = () => {
        if (this.state.open) {
            return (
                <AddPhase 
                    open={this.state.open}
                    title='Add a Faze'
                    darkMode={this.props.darkMode}
                    minDate={this.state.phases.length > 0 && new Date(this.state.phases[this.state.phases.length - 1].endDate)}
                    cancel={this.cancelPhase}
                    save={this.savePhase}
                    saveAdd={this.savePhaseAndAdd}
                    phaseTypes={this.props.phaseTypes}
                />
            )
        }

    }

    renderEditDialog = () => {
        if (this.state.editOpen) {
            return (
                <EditPhase 
                    open={this.state.editOpen}
                    title={`Editing ${this.state.editPhase.phase.name} Faze`}
                    darkMode={this.props.darkMode}
                    phase={this.state.editPhase}
                    cancel={this.cancelEditPhase}
                    save={this.updatePhase}
                    phaseTypes={this.props.phaseTypes}
                    minDate={this.state.phases.length > 0 && new Date(this.state.phases[this.state.phases.length - 1].endDate)}
                />
            )
        }
    }

    decideInterval = (interval) => {
        if (!interval) {
            return 'None'
        }
        if (interval == 1) {
            return 'Every day'
        }
        if (interval > 1) {
            return `Every ${interval} days`
        }
    }

    renderPhase = (phase, index) => {
        let emailCount = createEmailList([phase]).length; 
        return (
            <TableRow index={index} darkMode={this.props.darkMode} key={index}>
                <TableCell>
                    <p>{phase.phase.name}</p>
                </TableCell>
                <TableCell>
                    <p>{parseTimeToReadableDate(phase.startDate)}</p>
                </TableCell>
                <TableCell>
                    <p>{parseTimeToReadableDate(phase.endDate)}</p>
                </TableCell>
                <TableCell>
                    <p>{this.decideInterval(phase.interval)}</p>
                </TableCell>
                <TableCell>
                    <p>{emailCount}</p>
                </TableCell>
                <TableCell>
                    <p>{phase.days}</p>
                </TableCell>
                <TableCell>
                    <IconMenu
                        iconButtonElement={<IconButton><NavigationMoreVert color={this.props.darkMode && '#fff'} /></IconButton>}
                    >
                        <MenuItem primaryText='Delete' leftIcon={<ActionDelete />} onClick={() => this.openDeleteDialog(phase.phase, index)} />
                        <Divider />
                        <MenuItem primaryText='Update' leftIcon={<ImageEdit />} onClick={() => this.openEditDialog(phase, index)} />
                    </IconMenu>
                </TableCell>
            </TableRow>
        )
    }

    renderPhases = () => {
        const { phases } = this.state;
        return phases.map(this.renderPhase);
    }

    renderTable = () => {
        return (
            <Table
                showEmpty={this.state.phases.length === 0}
                showInfo={this.state.phases.length > 0}
                add={this.openPhaseDialog}
                addLabel='Add faze'
                placeholder='Search by Build Phase name'
                infoLabel={`${this.state.phases.length} phase(s)`}
                search={this.state.search}
                hideSearch
                onChange={this.searchPhases}
                darkMode={this.props.darkMode}
                emptyMessage='You have no fazes yet.'
                errorText={this.state.scheduleError && 'At least one phase is required'}
                headers={['Phase Name', 'Start Date', 'End Date', 'Daily Email Interval', 'Email Count', 'Business Days', 'Options']}
            >
                {this.renderPhases()}
            </Table>
        )
    }

    render = () => {
        return(
            <Template darkMode={this.props.darkMode} title='Add an Email Schedule'>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
                {this.renderEditDialog()}
                <div className='AddEmailSchedule-Container'>
                    {this.renderTopRow()}
                    {this.renderTable()}
                    <RaisedButton 
                        style={styles.save}
                        label='Save Email Schedule' 
                        backgroundColor='#56ACF2'
                        labelColor='#fff'
                        onClick={this.saveSchedule}
                    />
                </div>
            </Template>
        )
    }
}

const styles = {
    save: {
        width: '90%', 
        marginTop: 20
    },
    select: {
        width: 400
    },
    dialogContent: {
        width: '35vw',
    },
    title: {
        backgroundColor: '#50473A', 
        color: '#fff'
    },
    darkTitle: {
        backgroundColor: '#56ACF2', 
        color: '#fff'
    }, 
    margin: {
        marginLeft: 15
    },
    darkDialog: {
        backgroundColor: '#262525'
    },
    float:  { color: 'rgba(255, 255, 255, 0.7)' },
    label: { color: '#fff' }
}

const mapStateToProps = (state) => {
    const { clients } = state.clients;
    const { darkMode } = state.dark;
    const { phaseTypes } = state.phase;
    return { clients, darkMode, phaseTypes }
}

export default connect(mapStateToProps, { saveSchedule })(AddEmailSchedule);