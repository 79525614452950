import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { Table, Template, TableRow, TableCell } from '../CommonComponents';
import { searchNotifications } from '../../actions/AlertActions';
import { RaisedButton, IconButton } from 'material-ui';
import './styles/NotificationsManager.css';
import { EDIT_SCHEDULE } from '../../constants/routes';
import { ImageEdit, ActionDelete } from 'material-ui/svg-icons';

class NotificationsManager extends Component {

    deleteNotification = (id) => {
        firebase.firestore().doc(`alerts/${id}`).delete().then();
    }

    search = (search) => {
        this.props.searchNotifications(search, this.props.notifications);
    }

    changeSchedule = (alert, notificationId) => {
        this.deleteNotification(notificationId);
        window.location.hash = `/${EDIT_SCHEDULE}/${alert.scheduleId}`
    }

    renderTable = () => {
        return (
            <Table 
                placeholder='Search by Message...'
                headers={['Email Schedule Name', 'Message','Change Schedule', 'Dismiss']}
                showEmpty={this.props.notifications.length === 0}
                showInfo={this.props.notifications.length > 0}
                infoLabel={`${this.props.notifications.length} notification(s)`}
                hideAdd
                onChange={this.search}
                search={this.props.search}
                emptyMessage='You have no notifications.'
                darkMode={this.props.darkMode}
            >
                {this.renderNotifications()}
            </Table>
        )
    }
    
    renderNotification = (notification, index) => {
        return (
            <TableRow darkMode={this.props.darkMode} key={index} index={index}>
                <TableCell>
                    <p>{notification.data().name}</p>
                </TableCell>
                <TableCell>
                    <p>{notification.data().message}</p>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.changeSchedule(notification.data(), notification.id)}>
                        <ImageEdit color='#56ACF2' />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.deleteNotification(notification.id)}>
                        <ActionDelete color='salmon' />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    renderNotifications = () => {
        const { results, notifications } = this.props;
        const list = results.length > 0 ? results : notifications;
        return list.map(this.renderNotification);
    }

    render = () => {
        return (
            <Template darkMode={this.props.darkMode} title='My Notifications'>
                {this.renderTable()}
            </Template>
        )
    }
}

const mapStateToProps = (state) => {
    const { notifications, search, results } = state.alert;
    const { darkMode } = state.dark;
    return { notifications, search, results, darkMode }
}

export default connect(mapStateToProps, { searchNotifications })(NotificationsManager);