import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import './styles/Dialog.css';

const Modal = ({ title, open, actions, darkMode, children }) => {
    return (
        <Dialog
            title={title}
            open={open}
            actions={actions}
            contentStyle={styles.dialogContent}
            bodyStyle={darkMode && styles.darkDialog}
            titleStyle={darkMode ? styles.darkTitle : styles.title}
            actionsContainerStyle={darkMode && styles.darkDialog}
        >
            { 
                children && <div className='Dialog-Container'>
                    {children}
                </div>
            }
        </Dialog>
    )
}

Modal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    actions: PropTypes.array,
    darkMode: PropTypes.bool,
    children: PropTypes.node
};

Modal.defaultProps = {
    title: '',
    open: false,
    actions: [],
    darkMode: false,
    children: null
}

const styles = {
    dialogContent: {
        width: '35vw',
    },
    title: {
        backgroundColor: '#56ACF2', 
        color: '#fff'
    },
    darkTitle: {
        backgroundColor: '#56ACF2', 
        color: '#fff'
    }, 
    darkDialog: {
        backgroundColor: '#262525'
    },
}

export default Modal;