import React from 'react';
import PropTypes from 'prop-types';
import './styles/TabNav.css';
import { ActionHome, ActionAccountCircle, CommunicationEmail, SocialNotifications, ActionBuild } from 'material-ui/svg-icons';
import { HOME, EMAIL, CLIENTS, NOTIFICATIONS, PHASES  } from '../../constants/routes';

const TabNav = ({ type, selected, showAlertBadge, alertCount, darkMode }) => {

    const navToHome = () => {
        window.location.hash = `/${HOME}`;
    }

    const navToClients = () => {
        window.location.hash = `/${CLIENTS}`;
    }

    const navToEmails = () => {
        window.location.hash = `/${EMAIL}`;
    }

    const navToNotifications = () => {
        window.location.hash  = `/${NOTIFICATIONS}`;
    }

    const navToPhases = () => {
        window.location.hash = `/${PHASES}`;
    }

    const decideLabel = (selected) => {
        if (darkMode) {
            if (selected) {
                return 'TabNav-ActiveLabelDark'
            }
            return 'TabNav-LabelDark';
        }
        if (selected) {
            return 'TabNav-ActiveLabel';
        }
        return 'TabNav-Label';
    }

    const decideIcon = (selected) => {
        if (darkMode) {
            if (selected) {
                return styles.activeIconDark;
            }
            return styles.iconDark;
        }
        if (selected) {
            return styles.activeIcon;
        }
        return styles.inactiveIcon;
    }

    const renderHome = () => {
        return (
            <div 
                className='TabNav-Container'
                onClick={navToHome}
            >
                <p className={decideLabel(selected)}>Home</p>
                <ActionHome color={decideIcon(selected)} />
                {selected && <div className={darkMode ? 'TabNav-SelectedLineDark' : 'TabNav-SelectedLine'} />}
            </div>
        )
    }

    const renderPhases = () => {
        return (
            <div 
                className='TabNav-Container'
                onClick={navToPhases}
            >
                <p className={decideLabel(selected)}>Faze Types</p>
                <ActionBuild color={decideIcon(selected)} />
                {selected && <div className={darkMode ? 'TabNav-SelectedLineDark' : 'TabNav-SelectedLine'} />}
            </div>
        )
    }

    const renderClients = () => {
        return (
            <div 
                className='TabNav-Container'
                onClick={navToClients}
            >
                <p className={decideLabel(selected)}>Clients</p>
                <ActionAccountCircle color={decideIcon(selected)} />
                {selected && <div className={darkMode ? 'TabNav-SelectedLineDark' : 'TabNav-SelectedLine'} />}
            </div>
        )
    }

    const renderEmail = () => {
        return (
            <div 
                className='TabNav-Container'
                onClick={navToEmails}
            >
                <p className={decideLabel(selected)}>Email Schedules</p>
                <CommunicationEmail color={decideIcon(selected)} />
                {selected && <div className={darkMode ? 'TabNav-SelectedLineDark' : 'TabNav-SelectedLine'} />}
            </div>
        )
    }

    const renderNotifications = () => {
        return (
            <div 
                className='TabNav-Container'
                onClick={navToNotifications}
            >
                <p className={decideLabel(selected)}>Notifications</p>
                <SocialNotifications color={decideIcon(selected)} />
                {selected && <div className={darkMode ? 'TabNav-SelectedLineDark' : 'TabNav-SelectedLine'} />}
                {showAlertBadge && <div className='TabNav-Badge'><p className='TabNav-BadgeCount'>{alertCount}</p></div>}
        </div>
        )
    }
    
    

    const renderDecideType = () => {
        switch (type) {
            case HOME:
                return renderHome();
            case NOTIFICATIONS:
                return renderNotifications();
            case EMAIL:
                return renderEmail();
            case CLIENTS:
                return renderClients();
            case PHASES:
                return renderPhases();
            default:
                return renderHome();
        }
    }

    return renderDecideType();
}

const styles = {
    inactiveIcon: '#fff',
    activeIcon: '#fff',

    activeIconDark: '#56ACF2',
    iconDark: '#fff'
}

TabNav.propTypes = {
    type: PropTypes.oneOf([HOME, NOTIFICATIONS, EMAIL, CLIENTS]),
    selected: PropTypes.bool,
    darkMode: PropTypes.bool,
    showAlertBadge: false,
    alertCount: 0
};

TabNav.defaultProps = {
    type: HOME,
    selected: false,
    darkMode: false,
    showAlertBadge: false,
    alertCount: 0
}

export default TabNav;