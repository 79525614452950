import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/AddPhase.css';
import Modal from './Modal';
import guid from 'guid';
import { FlatButton, MenuItem } from 'material-ui';
import { BUILD_PHASES } from '../../constants/buildPhases';
import Dropdown from './Dropdown';
import Input from './Input';
import DateInput from './DateInput';
import { findEndTime, getDaysInBetween, parseTimeTo12amTime, addTime } from '../../utilities/DateUtil';

const DOWNTIME = {
    desc: 'Period of time when crews are prepping for the next phase.',
    name: 'Downtime'
}

const MIN_DAYS = 6;

class AddPhase extends Component {

    constructor(props) {
        super(props);
        const startDate = this.props.minDate ? this.props.minDate : assignDefaultMinDate();
        this.state = {
            startDate,
            days: MIN_DAYS,
            phase: DOWNTIME,
            interval: 1,
        }
    }

    actions = [
        <FlatButton 
            label='Save and Add'
            labelStyle={{ color: '#4D93CC' }}
            onClick={() => this.saveAndAdd()}
        />,
        <FlatButton 
            label='save'
            labelStyle={{ color: '#4D93CC' }}
            onClick={() => this.save()}
        />,
        <FlatButton 
            label='cancel'
            labelStyle={{ color: 'salmon' }}
            onClick={() => this.cancel()}
        />
    ]

    manualActions = [
        <FlatButton 
            label='save'
            labelStyle={{ color: '#4D93CC' }}
            onClick={() => this.save()}
        />,
        <FlatButton 
            label='cancel'
            labelStyle={{ color: 'salmon' }}
            onClick={() => this.props.cancel()}
        />
    ]

    phaseChange = (phase) => {
        this.setState({ phase });
    }

    intervalChange = (interval) => {
        const intervalInfo = this.getIntervalInfo();
        if (interval <= intervalInfo.max && interval > 0) {
            this.setState({ interval })
        }
    }

    startChange = (startDate) => {
        this.setState({ startDate });
    }

    daysChange = (days) => {
        if (days >= MIN_DAYS) {
            this.setState({ days });
        }
    }

    lastPhaseChange = (event, lastPhase) => {
        this.setState({ lastPhase });
    }

    getIntervalInfo = () => {
        const { startDate, days } = this.state;
        const endTime = findEndTime(startDate.getTime(), days);
        const differenceInDays = getDaysInBetween(startDate.getTime(), endTime) - 2;
        const label = `Email Interval (${differenceInDays} DAY MAX)*`;
        return { label, max: differenceInDays };
    }

    getPack = () => {
        const { startDate, days, interval, phase  } = this.state;
        const endTime = findEndTime(startDate.getTime(), days);
        const pack = {
            startDate: startDate.getTime(),
            endDate: endTime,
            interval,
            days,
            phase,
            guid: guid.create().value
        };
        return pack;
    }

    save = () => {
        const pack = this.getPack();
        this.props.save(pack);
    }

    cancel = () => {
        this.setState({
            startDate: this.props.minDate,
            days: MIN_DAYS,
            phase: DOWNTIME,
            interval: 1,
        });
        this.props.cancel();
    }

    saveAndAdd = () => {
        const pack = this.getPack();
        this.setState({
            interval: 1,
            days: MIN_DAYS,
            phase: DOWNTIME,
            lastPhase: false,
            minDate: new Date(pack.endDate),
            startDate: new Date(pack.endDate)
        });
        this.props.saveAdd(pack);
    }

    render = () => {
        const intervalInfo = this.getIntervalInfo();
        return (
            <Modal
                open={this.props.open}
                title={this.props.title}
                darkMode={this.props.darkMode}
                actions={this.props.manual ? this.manualActions : this.actions}
            >
                <div className='AddPhase-Row'>
                    <DateInput 
                        floatingLabelText='Start Date*'
                        onChange={this.startChange}
                        minDate={this.props.minDate}
                        darkMode={this.props.darkMode}
                        value={this.state.startDate}
                    />
                    <Input 
                        type='number'
                        darkMode={this.props.darkMode}
                        floatingLabelText={`# of work days (min ${MIN_DAYS} days)`}
                        style={styles.marginLeft}
                        value={this.state.days}
                        onChange={this.daysChange}
                    />
                </div>
                <div className='AddPhase-Row'>
                    <Dropdown
                        floatingLabelText='Build Phase*'
                        value={this.state.phase}
                        darkMode={this.props.darkMode}
                        onChange={this.phaseChange}
                    >
                        {this.props.phaseTypes.map((buildPhase, index) => <MenuItem key={index} primaryText={buildPhase.name} value={buildPhase} />)}
                    </Dropdown>
                    <Input 
                        type='number'
                        floatingLabelText={intervalInfo.label}
                        style={styles.marginLeft}
                        darkMode={this.props.darkMode}
                        value={this.state.interval}
                        onChange={this.intervalChange}
                    />
                </div>
            </Modal>
        )
    }
}

const assignDefaultMinDate = () => {
    const date = new Date();
    if (date.getDay() === 0) {
        const am12 = parseTimeTo12amTime(date.getTime());
        const minTime = addTime(am12, 1);
        return new Date(minTime);
    } else if (date.getDay() === 6) {
        const am12 = parseTimeTo12amTime(date.getTime());
        const minTime = addTime(am12, 2);
        return new Date(minTime);
    } else {
        return new Date(parseTimeTo12amTime(date.getTime()));
    }
}

AddPhase.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    darkMode: PropTypes.bool,
    minDate: PropTypes.object,
    save: PropTypes.func,
    cancel: PropTypes.func,
    saveAdd: PropTypes.func,
    phaseTypes: PropTypes.array
};

AddPhase.defaultProps = {
    open: false,
    title: '',
    darkMode: false,
    minDate: null,
    save: () => {},
    cancel: () => {},
    saveAdd: () => {},
    phaseTypes: []
}

const styles = {
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 10
    }
}

export default AddPhase;