import { FETCH_PHASE_TYPES, PHASE_PROP_CHANGE } from "../actionTypes/PhaseActions";

const INITIAL_STATE = {
    phaseTypes: [],
    phaseHash: {},
    search: '',
    results: [],
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PHASE_TYPES:
            return { ...state, phaseTypes: action.payload.phaseTypes, phaseHash: action.payload.phaseHash }
        case PHASE_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value }
        default:
            return state;
    }
}