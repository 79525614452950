import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'material-ui';

const DateInput = ({ floatingLabelText, value, darkMode, onChange, minDate, maxDate, errorText, style, disabled }) => {

    const change = (event, value) => {
        if (value.getDay() !== 0 && value.getDay() !== 6) {
            onChange(value)
        } else {
            alert('Only business days are allowed')
        }
    }

    return (
        <DatePicker 
            floatingLabelText={floatingLabelText}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            floatingLabelStyle={darkMode ? styles.darkFloat : styles.float}
            inputStyle={darkMode && styles.darkInput}
            errorText={errorText}
            onChange={change}
            locale='en-US'
            mode='portrait'
            disabled={disabled}
            firstDayOfWeek={0}
            autoOk
            style={style}
        />
    )
}

const styles = {
    darkFloat: {
        color: 'rgba(255, 255, 255, 0.7)'
    },
    darkInput: {
        color: '#fff'
    },
    float: { color: 'rgba(0, 0, 0, 0.8'}
}

export default DateInput;