import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import ClientManagement from '../ClientComponents/ClientManagement';
import EmailManager from '../EmailComponents/EmailManager';
import NavBar from './NavBar';
import logo from '../../assets/darkLogo.png';
import './styles/Home.css';
import firebase from 'firebase';
import { CLIENTS, EMAIL, NOTIFICATIONS, HOME, ADD_EMAIL, EDIT_SCHEDULE, PHASES } from '../../constants/routes';
import NotificationsManager from '../NotificationComponents/NotificationsManager';
import Dash from './Dash';
import AddEmailSchedule from '../EmailComponents/AddEmailSchedule';
import { fetchClients } from '../../actions/ClientActions';
import { fetchSchedules } from '../../actions/EmailActions';
import { toggleDark } from '../../actions/DarkActions';
import EditEmailSchedule from '../EmailComponents/EditEmailSchedule';
import { fetchNotifications } from '../../actions/AlertActions';
import { fetchPhaseTypes } from '../../actions/PhaseActions';
import { IconMenu, MenuItem, IconButton } from 'material-ui';
import { NavigationMoreVert } from 'material-ui/svg-icons';
import PhaseManager from '../PhaseComponents/PhaseManager';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentRoute: '',
            user: null
        }
    }

    componentDidMount = () => {
        this.createUrlListener();
        this.getInitialRoute();
        this.props.fetchClients();
        this.props.fetchSchedules();
        this.listenToAuthChange();
        this.props.fetchNotifications();
        this.getTheme();
        this.props.fetchPhaseTypes();
    }

    getTheme = () => {
        const darkMode = sessionStorage.getItem('darkMode');
        this.props.toggleDark(darkMode === 'true');
    }

    logOut = () => {
        firebase.auth().signOut();
    }

    toggleManual = () => {
        this.props.toggleManual(this.props.manual)
    }

    listenToAuthChange = () => {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                window.location.hash = '/';
            }
            if (user) {
                console.log(user);
                this.setState({ user });
            }
        })
    }

    getInitialRoute = () => {
        if (window.location.href.includes(EDIT_SCHEDULE)) {
            this.setState({ currentRoute: EDIT_SCHEDULE });
        } else {
            const piecesInRoute = window.location.href.split('/');
            const currentRoute = piecesInRoute[piecesInRoute.length - 1];
            this.setState({ currentRoute })
        }

    }

    createUrlListener = () => {
        window.addEventListener('hashchange', this.updateRoute, false);
    }

    updateRoute = (event) => {
        const { newURL } = event;
        if (newURL.includes(EDIT_SCHEDULE)) {
            this.setState({ currentRoute: EDIT_SCHEDULE });
        } else {
            const piecesInRoute = newURL.split('/');
            const currentRoute = piecesInRoute[piecesInRoute.length - 1];
            this.setState({ currentRoute })
        }
    }

    changeDarkMode = () => {
        this.props.toggleDark(!this.props.darkMode);
    }

    renderAvatar = () => {
        if (this.state.user) {
            return (
                <div className='Home-Avatar'><h2>{this.state.user.email[0].toUpperCase()}</h2></div>
            )
        }
    }

    render = () => {
        return (
            <div className='Home-Container'>
                <div className={this.props.darkMode ? 'Home-NavBarDark' : 'Home-NavBar'}>
                    <div className='Home-NavBarLeft'>
                        <img src={logo} style={{ width: 45, height: 45, marginRight: 10, marginLeft: 20 }} />
                        <NavBar 
                            currentRoute={this.state.currentRoute} 
                            showAlertBadge={this.props.notifications.length > 0}
                            alertCount={this.props.notifications.length}
                            darkMode={this.props.darkMode}
                        />
                    </div>

                    <div className='Home-NavBarRight'>
                        <IconMenu
                            iconButtonElement={<IconButton><NavigationMoreVert color={'#fff'} /></IconButton>}
                        >
                            <MenuItem primaryText='Toggle Dark Mode' onClick={this.changeDarkMode} />
                            <MenuItem primaryText='Log Out' onClick={this.logOut} />
                        </IconMenu>
                        {this.renderAvatar()}
                    </div>

                </div>
                <div className='Home-Body'>
                    <Route path={`/${HOME}`} component={Dash} />
                    <Route path={`/${CLIENTS}`} component={ClientManagement} />
                    <Route path={`/${EMAIL}`} component={EmailManager} />
                    <Route path={`/${ADD_EMAIL}`} component={AddEmailSchedule} />
                    <Route path={`/${NOTIFICATIONS}`} component={NotificationsManager} />
                    <Route path={`/${EDIT_SCHEDULE}`} component={EditEmailSchedule} />
                    <Route path={`/${PHASES}`} component={PhaseManager} />
                </div>
            </div>
        )
    }
}

const styles = {
    toggle: {
        width: 150, 
        marginRight: 20
    },
    darkLabel: {
        color: '#fff'
    }
}

const mapStateToProps = (state) => {
    const { notifications } = state.alert;
    const { darkMode } = state.dark;
    return { notifications, darkMode };
}

export default connect(mapStateToProps, { 
    fetchClients, 
    fetchSchedules, 
    fetchNotifications, 
    toggleDark, 
    fetchPhaseTypes
})(Home);