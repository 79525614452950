import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'material-ui';

const Dropdown = ({ floatingLabelText, style, value, onChange, errorText, darkMode, children }) => { 

    const change = (event, key, client) => {
        onChange(client);
    }

    const selectionRenderer = (value) => {
        return value.name;
    }

    return (
        <SelectField
            floatingLabelText={floatingLabelText}
            style={style}
            value={value}
            errorText={errorText}
            darkMode={darkMode}
            onChange={change}
            floatingLabelStyle={darkMode ? styles.darkFloat : styles.float}
            labelStyle={darkMode && styles.label}
        >
            {children}
        </SelectField>
    )
}; 

const styles = {
    darkFloat:  { color: 'rgba(255, 255, 255, 0.7)' },
    label: { color: '#fff' },
    float: { color: 'rgba(0, 0, 0, 0.8'}
    
}

Dropdown.propTypes = {
    floatingLabelText: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    errorText: PropTypes.string,
    darkMode: PropTypes.bool
};

Dropdown.defaultProps = {
    floatingLabelText: '',
    style: {},
    value: null,
    onChange: () => {},
    errorText: '',
    darkMode: false
}

export default Dropdown;