import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Template, Table, TableRow, TableCell } from '../CommonComponents';
import axios from 'axios';
import firebase from 'firebase';
import { EDIT_SCHEDULE, EMAIL } from '../../constants/routes';
import './styles/EditEmailSchedule.css';
import { IconButton, FlatButton } from 'material-ui';
import { parseTimeToReadableDate, findEndTime, parseTimeTo12amTime, accountForWeekend, addTime } from '../../utilities/DateUtil';
import { ActionDelete, ImageEdit } from 'material-ui/svg-icons';
import Modal from '../CommonComponents/Modal';
import { createEmailList } from '../../utilities/EmailUtil';
import AddPhase from '../CommonComponents/AddPhase';
import EditPhase from '../CommonComponents/EditPhase';

class EditEmailSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            schedule: null,
            deleteOpen: false,
            phaseToDelete: null,
            deleteIndex: 0,
            editIndex: 0,
            phaseToEdit: null,

            editOpen: false,
            addOpen: false,
            sending: false
        }
    }
    
    componentDidMount = () => {
        this.grabScheduleIdFromURL();
    }

    deleteActions = [
        <FlatButton 
            label='Delete'
            style={{ color: 'salmon' }}
            onClick={() => this.deletePhase()}
        />,
        <FlatButton 
            label='Cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelDeletePhase()}
        />
    ];

    updateActions = [
        <FlatButton 
            label='Update'
            style={{ color: '#56ACF2' }}
            onClick={() => this.updatePhase()}
        />,
        <FlatButton 
            label='Cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelPhaseEdit()}
        />
    ]

    sendEmail = async () => {
        this.setState({ sending: true });
        await axios.post('https://us-central1-scheduler-d1188.cloudfunctions.net/sendEmail', { id: this.state.schedule.id });
        this.setState({ sending: false });
    }

    cancelDeletePhase = () => {
        this.setState({
            deleteIndex: 0,
            phaseToDelete: null,
            deleteOpen: false
        })
    }

    openAdd = () => {
        this.setState({ addOpen: !this.state.addOpen });
    }

    deletePhase = async () => {
        const today = new Date().getTime();
        const phases = [ ...this.state.schedule.phases ];
        const phaseToDelete = this.state.schedule.phases[this.state.deleteIndex];
        if (phases.length === 1) {
            await firebase.firestore().doc(`schedules/${this.state.schedule.id}`).delete();
            return;
        }
        if (today >= phaseToDelete.startDate && today <= phaseToDelete.endDate) {
            if (this.state.deleteIndex < phases.length) {
                for (let i = this.state.deleteIndex + 1; i < phases.length; i++) {
                    if (i === this.state.deleteIndex + 1) {
                        const phase = phases[i];
                        phase.startDate = accountForWeekend(parseTimeTo12amTime(today));
                        phase.endDate = findEndTime(phase.startDate, phase.days);
                        phases[i] = phase;
                    } else {
                        const phase = phases[i];
                        phase.startDate = phases[i - 1].endDate;
                        phase.endDate = findEndTime(phase.startDate, phase.days);
                        phases[i] = phase;
                    }
                }
                phases.splice(this.state.deleteIndex, 1);
                this.cancelDeletePhase();
                await firebase.firestore().doc(`schedules/${this.state.schedule.id}`).update({
                    phases: phases
                })
                await this.sendEmail();
            }
        } else if (today < phaseToDelete.startDate) {
            if (this.state.deleteIndex < phases.length) {
                for (let i = this.state.deleteIndex + 1; i < phases.length; i++) {
                    if (i === this.state.deleteIndex + 1) {
                        const phase = phases[i];
                        phase.startDate = phaseToDelete.startDate;
                        phase.endDate = findEndTime(phase.startDate, phase.days);
                        phases[i] = phase;
                    } else {
                        const phase = phases[i];
                        phase.startDate = phases[i - 1].endDate;
                        phase.endDate = findEndTime(phase.startDate, phase.days);
                        phases[i] = phase;
                    }
                }
                phases.splice(this.state.deleteIndex, 1);
                this.cancelDeletePhase();
                await firebase.firestore().doc(`schedules/${this.state.schedule.id}`).update({
                    phases: phases
                })
            }
        }

    }

    updatePhase = async (phase) => {
        const phases = [...this.state.schedule.phases];
        phases[this.state.editIndex] = phase;
        if (this.state.editIndex < phases.length) {
            const index = this.state.editIndex + 1;
            for (let i = index; i < phases.length; i++) {
                const phase = phases[i];
                phase.startDate = phases[i - 1].endDate;
                phase.endDate = findEndTime(phase.startDate, phase.days);
                phases[i] = phase;
            }
        }
        this.cancelPhaseEdit();
        await firebase.firestore().doc(`schedules/${this.state.schedule.id}`).update({
            phases: phases
        })
    }

    grabScheduleIdFromURL = () => {
        const routes = window.location.hash.split('/');
        if (routes[routes.length - 1] !== EDIT_SCHEDULE) {
            const scheduleId = decodeURI(routes[routes.length - 1]);
            this.fetchSchedule(scheduleId);
        } else {
            window.location.hash = `/${EMAIL}`
        }
    }

    setPhaseToDelete = (phaseToDelete, deleteIndex) => {
        this.setState({
            phaseToDelete,
            deleteOpen: true,
            deleteIndex
        });
    }

    setPhaseToEdit = (phaseToEdit, editIndex) => {
        this.setState({
            phaseToEdit,
            editIndex,
            editOpen: true
        })
    }

    cancelPhaseEdit = () => {
        this.setState({
            editIndex: 0,
            phaseToEdit: null,
            editOpen: false
        });
    }

    cancelPhaseAdd = () => {
        this.setState({
            addOpen: false
        })
    }

    savePhaseAdd = async (phase) => {
        this.setState({ addOpen: false });
        const phases = [...this.state.schedule.phases];
        phases.push(phase);
        await firebase.firestore().doc(`schedules/${this.state.schedule.id}`).update({
            phases: phases
        })
    }

    savePhaseAddAnother = async (phase) => {
        const phases = [...this.state.schedule.phases];
        phases.push(phase);
        await firebase.firestore().doc(`schedules/${this.state.schedule.id}`).update({
            phases: phases
        })
    }

    fetchSchedule = (scheduleId) => {
        firebase.firestore().doc(`schedules/${scheduleId}`).onSnapshot(snap => {
            if (snap.exists) {
                this.setState({ schedule: { ...snap.data(), id: scheduleId }});
            } else {
                window.location.hash = `/${EMAIL}`
            }

        })
    }

    getStatus = (phase) => {
        const now = parseTimeTo12amTime(new Date().getTime());
        if (now === phase.startDate) {
            return 'Started';
        }
        if (now === phase.endDate) {
            return 'Done'
        }
        if (addTime(now, 1) === phase.startDate) {
            return 'Starting tomorrow'
        }
        if (addTime(now, 2) === phase.startDate) {
            return 'Starting in 2 days'
        }
        if (addTime(now, 3) === phase.startDate) {
            return 'Starting in 3 days';
        }
        if (addTime(now, 1) === phase.endDate) {
            return 'Ending tomorrow'
        }
        if (addTime(now, 2) === phase.endDate) {
            return 'Ending in 2 days'
        }
        if (addTime(now, 3) === phase.endDate) {
            return 'Ending in 3 days';
        }
        if (now >= phase.startDate && now <= phase.endDate) {
            return 'In Progress';
        }
        return 'Not yet started'
    }

    decideTitle = () => {
        if (!this.state.schedule) {
            return 'Loading Email Schedule to Edit...'
        }
        return `Editing Email Schedule for ${this.state.schedule.clientName}`;
    }

    decideInterval = (interval) => {
        if (!interval) {
            return 'None'
        }
        if (interval == 1) {
            return 'Every day'
        }
        if (interval > 1) {
            return `Every ${interval} days`
        }
    }

    renderDeleteTitle = () => {
        const today = new Date().getTime();
        const phaseToDelete = this.state.schedule.phases[this.state.deleteIndex];
        if (this.state.schedule.phases.length === 1) {
            return `Are you sure you want to delete the ${this.state.phaseToDelete.phase.name} phase? Because this is the last phase, the schedule will be deleted.`;
        }
        if (this.state.schedule.phases.length > 1 && today >= phaseToDelete.startDate && today <= phaseToDelete.endDate) {
            return `Are you sure you want to delete the ${this.state.phaseToDelete.phase.name} phase? If you do, an email will automatically be sent out reminding ${this.state.schedule.clientName} that the ${this.state.schedule.phases[this.state.deleteIndex + 1].phase.name} phase will start today.`;
        }
        return `Are you sure you want to delete the ${this.state.phaseToDelete.phase.name} phase? (IRREVERSIBLE OPERATION)`;
    }

    renderDeleteDialog = () => {
         if (this.state.deleteOpen) {
            return (
                <Modal 
                    title={this.renderDeleteTitle()}
                    actions={this.deleteActions}
                    darkMode={this.props.darkMode}
                    open={this.state.deleteOpen}
                />
            )
         }
    }

    renderDialog = () => {
        if (this.state.addOpen) {
            return (
                <AddPhase 
                    open={this.state.addOpen}
                    title='Add the next phase'
                    darkMode={this.props.darkMode}
                    minDate={new Date(this.state.schedule.phases[this.state.schedule.phases.length - 1].endDate)}
                    cancel={this.cancelPhaseAdd}
                    save={this.savePhaseAdd}
                    saveAdd={this.savePhaseAddAnother}
                    phaseTypes={this.props.phaseTypes}
                />
            )
        }
    }

    renderEditDialog = () => {
        if (this.state.editOpen) {
            return (
                <EditPhase 
                    open={this.state.editOpen}
                    title={`Editing ${this.state.phaseToEdit.phase.name} Faze`}
                    phase={this.state.phaseToEdit}
                    phaseTypes={this.props.phaseTypes}
                    darkMode={this.props.darkMode}
                    cancel={this.cancelPhaseEdit}
                    save={this.updatePhase}
                    minDate={this.state.editIndex > 0 && new Date(this.state.schedule.phases[this.state.editIndex - 1].endDate)}
                />
            )
        }
    }

    renderPhase = (phase, index) => {
        let emailCount = createEmailList([phase]).length; 
        return (
            <TableRow darkMode={this.props.darkMode} key={index} index={index}>
                <TableCell>
                    <p>{phase.phase.name}</p>
                </TableCell>
                <TableCell>
                    <p>{parseTimeToReadableDate(phase.startDate)}</p>
                </TableCell>
                <TableCell>
                    <p>{parseTimeToReadableDate(phase.endDate)}</p>
                </TableCell>
                <TableCell>
                    <p>{this.decideInterval(phase.interval)}</p>
                </TableCell>
                <TableCell>
                    <p>{emailCount.toString()}</p>
                </TableCell>
                <TableCell>
                    <p>{phase.days}</p>
                </TableCell>
                <TableCell>
                    <p>{this.getStatus(phase)}</p>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.setPhaseToEdit(phase, index)}>
                        <ImageEdit color='#56ACF2' />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.setPhaseToDelete(phase, index)}>
                        <ActionDelete color='salmon' />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    renderPhases = () => {
        return this.state.schedule.phases.map(this.renderPhase);
    }

    decideContent = () => {
        if (this.state.schedule) {
            return (
                <Table 
                    placeholder='Search by phase name'
                    headers={['Faze Name', 'Start Date', 'End Date', 'Daily Email Interval', 'Email Count', 'Business Days', 'Current Status', 'Update', 'Delete']}
                    showInfo
                    addLabel='Add Faze'
                    hideSearch
                    add={this.openAdd}
                    loadingSend={this.state.sending}
                    search={this.state.search}
                    onChange={this.searchPhases}
                    infoLabel={`${this.state.schedule.phases.length} phase(s)`}
                    showSend
                    send={this.sendEmail}
                    darkMode={this.props.darkMode}
                >
                    {this.renderPhases()}
                </Table>
            )

        }
    }

    render = () => {
        return (
            <Template darkMode={this.props.darkMode} title={this.decideTitle()}>
                {this.decideContent()}
                {this.renderDeleteDialog()}
                {this.renderEditDialog()}
                {this.renderDialog()}
            </Template>
        )
    }
}

const styles = {
    margin: {
        marginLeft: 15
    }
}

const mapStateToProps = (state) => {
    const { scheduleToEdit } = state.email;
    const { darkMode } = state.dark;
    const { phaseTypes } = state.phase;
    return { scheduleToEdit, darkMode, phaseTypes };
}

export default connect(mapStateToProps)(EditEmailSchedule);
