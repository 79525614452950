import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import firebase from 'firebase';
import { Provider } from 'react-redux';
import store from './reducers/store';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Auth } from './components/AuthComponents';
import './App.css';
import Home from './components/HomeComponents/Home';
import { HOME, EMAIL, CLIENTS, NOTIFICATIONS, ADD_EMAIL, EDIT_SCHEDULE, PHASES } from './constants/routes';

const app = firebase.initializeApp({
  apiKey: "AIzaSyBITwtdaZ5yg270v8Q7FNn9RUFS65N9zx4",
  authDomain: "scheduler-d1188.firebaseapp.com",
  databaseURL: "https://scheduler-d1188.firebaseio.com",
  projectId: "scheduler-d1188",
  storageBucket: "scheduler-d1188.appspot.com",
  messagingSenderId: "716014570086",
  appId: "1:716014570086:web:67068ee997d45449c4bba7",
  measurementId: "G-7JXQM9NJLL"
});


function App() {
  return (
    <div className='App-Container'>
      <Provider store={store}>
        <MuiThemeProvider>
          <HashRouter>
            <Switch basename="/">
                <Route exact path = "/" component={Auth} />
                <Route exact path = {`/${HOME}`} component={Home} />
                <Route exact path = {`/${CLIENTS}`} component={Home} />
                <Route exact path = {`/${EMAIL}`} component={Home} />
                <Route exact path = {`/${ADD_EMAIL}`} component={Home} />
                <Route exact path = {`/${NOTIFICATIONS}`} component={Home} />
                <Route path = {`/${EDIT_SCHEDULE}`} component={Home} />
                <Route path = {`/${PHASES}`} component={Home} />
            </Switch>
          </HashRouter>
        </MuiThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
