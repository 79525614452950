import React, { Component } from 'react';
import { Modal } from '../CommonComponents';
import { TextField, RaisedButton, CircularProgress, FlatButton } from 'material-ui';
import firebase from 'firebase';
import logo from '../../assets/trademark.png';
import './Styles/Auth.css';
import { EMAIL } from '../../constants/routes';
import Input from '../CommonComponents/Input';

class Auth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            emailError: false,
            passwordError: false,
            isAuthenticating: false,
            authError: false,
            passwordResetOpen: false,

            resetEmail: '',
            resetEmailError: false,
            resetEmailWrong: false
        }
    }

    actions = [
        <FlatButton 
            label='send reset email'
            labelStyle={{ color: '#55ACF2' }}
            onClick={() => this.reset()}
        />,
        <FlatButton 
            label='cancel'
            labelStyle={{ color: 'salmon' }}
            onClick={() => this.cancelReset()}
        />
    ]

    componentDidMount = () => {
        this.listenToAuthChange();
    }

    openReset = () => {
        this.setState({ passwordResetOpen: true })
    }

    cancelReset = () => {
        this.setState({ passwordResetOpen: false, resetEmail: '' })
    }

    listenToAuthChange = () => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ authError: false, isAuthenticating: false })
                window.location.hash = `/${EMAIL}`;
            }
        })
    }

    canAuth = () => {
        if (!this.state.email || !this.state.password) {
            return false
        }
        return true;
    }

    login = () => {
        const { email, password } = this.state;
        if (!this.canAuth()) {
            if (!email) {
                this.setState({ emailError: true });
            }
            if (!password) {
                this.setState({ passwordError: true });
            }
        } else {
            this.setState({ isAuthenticating: true, emailError: false, passwordError: false });
            firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
                this.setState({ authError: true, isAuthenticating: false });
            })
        }

    }

    emailChange = (event, email) => {
        this.setState({ email });
    }

    passwordChange = (event, password) => {
        this.setState({ password });
    }

    decideLoader = () => {
        if (this.state.isAuthenticating) {
            return (
                <CircularProgress 
                    size={20}
                    color='#56ACF2'
                    style={{ marginTop: 20 }}
                />
            )
        }
        return (
            <RaisedButton 
                style={styles.button}
                backgroundColor='#56ACF2'
                label='Log In'
                disabledLabelColor='#fff'
                disabledBackgroundColor='#468BC2'
                labelColor='#fff'
                onClick={this.login}
            />
        )
    }

    resetChange = (resetEmail) => {
        this.setState({ resetEmail, resetEmailError: false, resetEmailWrong: false });
    }

    canReset = () => {
        if (this.state.resetEmail) {
            return true;
        }
        return false;
    }

    reset = async () => {
        if (this.canReset()) {
            try {
                await firebase.auth().sendPasswordResetEmail(this.state.resetEmail).then();
                this.cancelReset();
            } catch (ex) {
                this.setState({ resetEmailWrong: true });
            }

        } else {
            this.triggerResetError();
        }
    }

    triggerResetError = () => {
        this.setState({ resetEmailError: true });
    }

    renderError = () => {
        if (this.state.authError) {
            return (
                <p className='Auth-Error'>Your email or password is incorrect</p>
            )
        }
    }

    decideError = () => {
        if (this.state.resetEmailError) {
            return 'Email is required.';
        }
        if (this.state.resetEmailWrong) {
            return 'Email is incorrect.';
        }
        return '';

    }

    renderForgotPassword = () => {
        if (this.state.passwordResetOpen) {
            return (
                <Modal 
                    open={this.state.passwordResetOpen}
                    title='Reset your password'
                    actions={this.actions}
                >
                    <Input 
                        floatingLabelText='Email'
                        value={this.state.resetEmail}
                        onChange={this.resetChange}
                        errorText={this.decideError()}
                    />
                </Modal>
            )
        }
    }

    render = () => {
        return (
            <div className='Auth-Container'>
                <img src={logo} style={{ width: 300, height: 300, marginBottom: 20, marginTop: 20 }} />
                <div className='Auth-InnerContainer'>
                    <h3 className='Auth-Header'>Welcome back!</h3>
                    <TextField 
                        style={styles.text}
                        floatingLabelText='Email'
                        value={this.state.email}
                        onChange={this.emailChange}
                        floatingLabelFocusStyle={styles.floatLabel}
                        underlineFocusStyle={styles.underline}
                        errorText={this.state.emailError && 'Email is required.'}
                    />
                    <TextField 
                        style={styles.text}
                        floatingLabelText='Password'
                        type='password'
                        value={this.state.password}
                        onChangeText
                        onChange={this.passwordChange}
                        floatingLabelFocusStyle={styles.floatLabel}
                        underlineFocusStyle={styles.underline}
                        errorText={this.state.passwordError && 'Password is required.'}
                    />
                    {this.renderError()}
                    <a className='Auth-Forgot' onClick={this.openReset}>Forgot your password?</a>
                    {this.decideLoader()}
                    {this.renderForgotPassword()}
                </div>
            </div>
        )
    }
}

const styles = {
    text: {
        width: '90%',
    },
    floatLabel: {
        color: '#56ACF2'
    },
    underline: {
        borderColor: '#56ACF2'
    },
    button: {
        width: '90%', 
        marginTop: 20
    }
}

export default Auth;