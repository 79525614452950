import { FETCH_SCHEDULES, SET_SCHEDULE_TO_EDIT, EMAIL_PROP_CHANGE } from '../actionTypes/EmailActions';

const INITIAL_STATE = {
    schedules: [],
    search: '',
    results: [],
    scheduleToEdit: null
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_SCHEDULES:
            return { ...state, schedules: action.payload };
        case SET_SCHEDULE_TO_EDIT:
            return { ...state, scheduleToEdit: action.payload };
        case EMAIL_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value }
        default:
            return state;
    }
}