import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Table, Template, TableRow, TableCell } from '../CommonComponents';
import './styles/EmailManager.css';
import { ADD_EMAIL, EDIT_SCHEDULE } from '../../constants/routes';
import { deleteSchedule, setScheduleToEdit , searchSchedules} from '../../actions/EmailActions';
import { parseTimeToReadableDate, getDaysInBetween, MS_IN_DAY, MS_IN_HOUR } from '../../utilities/DateUtil';
import { IconButton, Dialog, FlatButton } from 'material-ui';
import { NavigationMoreVert } from 'material-ui/svg-icons';
import { ActionDelete } from 'material-ui/svg-icons';
import { ImageEdit } from 'material-ui/svg-icons';
import Modal from '../CommonComponents/Modal';
import { createEmailList, getCurrentIndexInEmailList, DONE, PROGRESS } from '../../utilities/EmailUtil';

class EmailManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteOpen: false,
            scheduleToDelete: null,
            emailSending: false
        }
    }

    deleteActions = [
        <FlatButton 
            label='Delete'
            style={{ color: 'salmon' }}
            onClick={() => this.deleteSchedule()}
        />,
        <FlatButton 
            label='Cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelDelete()}
        />
    ];

    sendEmail = async () => {
        this.setState({ emailSending: true });
        await axios.post('https://us-central1-scheduler-d1188.cloudfunctions.net/testEmail').then();
        this.setState({ emailSending: false });
    }

    deleteSchedule = () => {
        this.props.deleteSchedule(this.state.scheduleToDelete.id);
        this.cancelDelete();
    }

    cancelDelete = () => {
        this.setState({ 
            deleteOpen: false,
            scheduleToDelete: null
        })
    }

    setScheduleToDelete = (scheduleSnap) => {
        this.setState({
            deleteOpen: true,
            scheduleToDelete: scheduleSnap
        });
    }

    navToEmailBuilder = () => {
        window.location.hash = ADD_EMAIL;
    }

    editSchedule = (scheduleSnap) => {
        window.location.hash = `/${EDIT_SCHEDULE}/${scheduleSnap.id}`;
    }

    search = (search) => {
        this.props.searchSchedules(search, this.props.schedules);
    }

    renderSchedule = (schedule, index) => {
        const emailList = createEmailList(schedule.data().phases);
        const activeIndex = getCurrentIndexInEmailList(emailList);
        let emailSent = null;
        let emailsLeft = null;
        let currentPhase = '';
        let currentStatus = '';
        if (activeIndex === -1) {
            currentPhase = 'Not yet started';
            currentStatus = 'N/A';
            emailSent = 0;
            emailsLeft = emailList.length;
        } else if (activeIndex === -2) {
            currentPhase = DONE;
            currentStatus = 'N/A';
            emailSent = emailList.length;
            emailsLeft = 0;
        } else if (activeIndex - Math.floor(activeIndex) > 0) {
            const index =  Math.floor(activeIndex);
            currentPhase = `Phase ${emailList[index].phaseIndex + 1}: ${emailList[index].phase}`;
            currentStatus = emailList[index].status;
            emailSent = index + 1;
            emailsLeft = emailList.length - emailSent;
        } else {
            currentPhase = `Phase ${emailList[activeIndex].phaseIndex + 1}: ${emailList[activeIndex].phase}`;
            currentStatus = emailList[activeIndex].status;
            emailSent = activeIndex + 1;
            emailsLeft = emailList.length - emailSent;
        }
        return (
            <TableRow darkMode={this.props.darkMode} index={index} key={index}>
                <TableCell>
                    <p>{schedule.data().clientName}</p>
                </TableCell>
                <TableCell>
                    <p>{schedule.data().phases.length}</p>
                </TableCell>
                <TableCell>
                    <p>{parseTimeToReadableDate(schedule.data().phases[0].startDate)}</p>
                </TableCell>
                <TableCell>
                    <p>{parseTimeToReadableDate(schedule.data().phases[schedule.data().phases.length - 1].endDate)}</p>
                </TableCell>
                <TableCell>
                    <p>{emailSent.toString()}</p>
                </TableCell>
                <TableCell>
                    <p>{emailsLeft.toString()}</p>
                </TableCell>
                <TableCell>
                    <p>{currentPhase}</p>
                </TableCell>
                <TableCell>
                    <p>{currentStatus}</p>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.editSchedule(schedule)}> >
                        <ImageEdit color='#56ACF2' />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.setScheduleToDelete(schedule)}>
                        <ActionDelete color='salmon' />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    renderDeleteDialog = () => {
        if (this.state.deleteOpen) {
            return (
                <Modal 
                    contentStyle={styles.dialogContent}
                    open={this.state.deleteOpen}
                    title={`Are you sure you want to delete the email schedule for ${this.state.scheduleToDelete.data().clientName}?`}
                    titleStyle={styles.title}
                    darkMode={this.props.darkMode}
                    actions={this.deleteActions}
                />
            )
        }
    }

    renderSchedules = () => {
        const { results, schedules } = this.props;
        const list = results.length > 0 ? results : schedules;
        return list.map(this.renderSchedule);
    }
    
    renderTable = () => {
        return (
            <Table 
                placeholder='Search by Client Name'
                headers={['Client Name', '# of Fazes', 'First Day', 'Last Day', 'Emails Sent', 'Emails Left', 'Current Phase', 'Current Status', 'Update', 'Delete']}
                showEmpty={this.props.schedules.length === 0}
                addLabel='Add Schedule'
                emptyMessage='You have no scheduled email jobs yet.'
                add={this.navToEmailBuilder}
                search={this.props.search}
                onChange={this.search}
                showInfo={this.props.schedules.length > 0}
                darkMode={this.props.darkMode}
                infoLabel={`${this.props.schedules.length} Schedule(s)`}
                showSend
                send={this.sendEmail}
                loadingSend={this.state.emailSending}
            >
                {this.renderSchedules()}
            </Table>
        )
    }

    render = () => {
        return (
            <Template darkMode={this.props.darkMode} title='Live Email Schedules'>
                {this.renderDeleteDialog()}
                {this.renderTable()}
            </Template>
        )
    }
}

const styles = {
    dialogContent: {
        width: '35vw'
    },
    title: {
        backgroundColor: '#50473A', 
        color: '#fff'
    },
}

const mapStateToProps = (state) => {
    const { schedules, search, results } = state.email;
    const { darkMode } = state.dark;
    return { schedules, search, results, darkMode }
}

export default connect(mapStateToProps, { 
    deleteSchedule, 
    setScheduleToEdit, 
    searchSchedules 
})(EmailManager);