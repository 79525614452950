import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';
import {Template, Table, TableRow, TableCell, Modal, Input} from '../CommonComponents';
import { IconButton, FlatButton } from 'material-ui';
import { ImageEdit, ActionDelete } from 'material-ui/svg-icons';
import { searchPhases } from '../../actions/PhaseActions';

class PhaseManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addOpen: false,
            name: '',
            desc: '',
            nameError: false,

            editOpen: false,
            phaseToEditKey: '',

            deleteOpen: false,
            phaseToDeleteKey: '',
            phaseToDelete: null
        }
    }

    actions = [
        <FlatButton 
            label='save and add another'
            labelStyle={{ color: '#56ACF2' }}
            onClick={() => this.saveAndAdd()}
        />,
        <FlatButton 
            label='save'
            labelStyle={{ color: '#56ACF2' }}
            onClick={() => this.save()}
        />,
        <FlatButton 
            label='cancel'
            labelStyle={{ color: 'salmon' }}
            onClick={() => this.cancelAdd()}
        />
    ]

    editActions = [
        <FlatButton 
            label='update'
            labelStyle={{ color: '#56ACF2' }}
            onClick={() => this.update()}
        />,
        <FlatButton 
            label='cancel'
            labelStyle={{ color: 'salmon' }}
            onClick={() => this.cancelEdit()}
        />
    ]

    deleteActions = [
        <FlatButton 
            label='delete'
            labelStyle={{ color: 'salmon' }}
            onClick={() => this.delete()}
        />,
        <FlatButton 
            label='cancel'
            labelStyle={{ color: 'salmon' }}
            onClick={() => this.cancelDelete()}
        />
    ]

    save = () => {
        if (this.canSave()) {
            this.toggleAdd();
            firebase.firestore().collection('phases').add({
                name: this.state.name,
                desc: this.state.desc,
                order: this.props.phaseTypes.length
            })
            this.setState({
                name: '',
                desc: '',
            })
        } else {
            this.triggerError();
        }
    }

    saveAndAdd = () => {
        if (this.canSave()) {
            firebase.firestore().collection('phases').add({
                name: this.state.name,
                desc: this.state.desc,
                order: this.props.phaseTypes.length
            })
            this.setState({
                name: '',
                desc: '',
            })
        } else {
            this.triggerError();
        }
    }

    canSave = () => {
        if (this.state.name) {
            return true;
        }
        return false;
    }

    triggerError = () => {
        this.setState({ nameError: true });
    }

    update = async () => {
        if (this.canSave()) {
            await firebase.firestore().doc(`phases/${this.state.phaseToEditKey}`).update({
                name: this.state.name,
                desc: this.state.desc
            });
            this.cancelEdit();
        } else {
            this.triggerError();
        }
    }

    cancelAdd = () => {
        this.setState({
            addOpen: false,
            name: '',
            desc: '',
            nameError: false
        })
    }

    cancelEdit = () => {
        this.setState({
            editOpen: false,
            name: '',
            desc: '',
            nameError: false
        })
    }

    cancelDelete = () => {
        this.setState({ 
            deleteOpen: false,
            phaseToDeleteKey: '',
            phaseToDelete: null
        })
    }

    delete = async () => {
        await firebase.firestore().doc(`phases/${this.state.phaseToDeleteKey}`).delete().then();
        this.cancelDelete();
    }

    descChange = (desc) => {
        this.setState({ desc });
    }

    nameChange = (name) => {
        this.setState({ name, nameError: false });
    }

    toggleAdd = () => {
        this.setState({ addOpen: !this.state.addOpen })
    }

    openEdit = (phase) => {
        const phaseToEditKey = this.props.phaseHash[phase.name];
        this.setState({
            editOpen: true,
            name: phase.name,
            desc: phase.desc,
            phaseToEditKey
        })
    }

    openDelete = (phase) => {
        const phaseToDeleteKey = this.props.phaseHash[phase.name];
        this.setState({
            deleteOpen: true,
            phaseToDelete: phase,
            phaseToDeleteKey
        })
    }

    search = (search) => {
        this.props.searchPhases(search, this.props.phaseTypes);
    }

    renderPhases = () => {
        const { phaseTypes, results } = this.props;
        const list = results.length > 0 ? results : phaseTypes;
        return list.map(this.renderPhase)
    }

    renderAddDialog = () => {
        if (this.state.addOpen) {
            return (
                <Modal
                    title='Add a new Faze Type'
                    open={this.state.addOpen}
                    darkMode={this.props.darkMode}
                    actions={this.actions}
                >
                    <Input 
                        floatingLabelText="Faze Type Name*"
                        value={this.state.name}
                        darkMode={this.props.darkMode}
                        fullWidth
                        errorText={this.state.nameError && 'Faze Type Name is required.'}
                        onChange={this.nameChange}
                    />
                    <Input 
                        floatingLabelText='Description'
                        fullWidth
                        value={this.state.desc}
                        darkMode={this.props.darkMode}
                        multiline
                        onChange={this.descChange}
                    />
                </Modal>
            )
        }
    }

    renderEditDialog = () => {
        if (this.state.editOpen) {
            return (
                <Modal
                    open={this.state.editOpen}
                    title={`Edit ${this.state.name} Faze`}
                    darkMode={this.props.darkMode}
                    actions={this.editActions}
                >
                    <Input 
                        floatingLabelText="Faze Type Name*"
                        value={this.state.name}
                        darkMode={this.props.darkMode}
                        fullWidth
                        onChange={this.nameChange}
                        errorText={this.state.nameError && 'Faze Type Name is required.'}
                    />
                    <Input 
                        floatingLabelText='Description'
                        fullWidth
                        value={this.state.desc}
                        darkMode={this.props.darkMode}
                        multiline
                        onChange={this.descChange}
                    />
                </Modal>
            )
        }
    }

    renderDeleteDialog = () => {
        if (this.state.deleteOpen) {
            return (
                <Modal
                    title={`Are you sure you want to delete ${this.state.phaseToDelete.name}?`}
                    darkMode={this.props.darkMode}
                    open={this.state.deleteOpen}
                    actions={this.deleteActions}
                />
            )
        }
    }

    truncateDesc = (desc) => {
        if (desc.length > 50) {
            return `${desc.substring(0, 50)}...`
        }
        return desc;
    }

    renderPhase = (phase, index) => {
        return (
            <TableRow key={index} index={index} darkMode={this.props.darkMode}>
                <TableCell>
                    <p>{phase.name}</p>
                </TableCell>
                <TableCell>
                    <p>{this.truncateDesc(phase.desc)}</p>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.openEdit(phase)}>
                        <ImageEdit color='#56ACF2' />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.openDelete(phase)}>
                        <ActionDelete color='salmon' />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    renderTable = () => {
        return (
            <Table
                placeholder='Search by Faze Name'
                headers={['Name', 'Desc', 'update', 'delete']}
                showEmpty={this.props.phaseTypes.length === 0}
                showInfo={this.props.phaseTypes.length > 0}
                infoLabel={`${this.props.phaseTypes.length} Fazes`}
                search={this.props.search}
                onChange={this.search}
                addLabel='Add Faze Type'
                add={this.toggleAdd}
                emptyMessage='You have no faze types'
                darkMode={this.props.darkMode}
            >
                {this.renderPhases()}
            </Table>
        )
    }

    render = () => {
        return (
            <Template darkMode={this.props.darkMode} title='My Fazes Types'>
                {this.renderTable()}
                {this.renderAddDialog()}
                {this.renderEditDialog()}
                {this.renderDeleteDialog()}
            </Template>
        )
    }
}

const mapStateToProps = (state) => {
    const { darkMode } = state.dark;
    const { phaseTypes, search, results, phaseHash } = state.phase;
    return { darkMode, phaseTypes, search, results, phaseHash };
}

export default connect(mapStateToProps, { searchPhases })(PhaseManager);
