import { TOGGLE_DARK } from "../actionTypes/DarkActions";

const INITIAL_STATE = {
    darkMode: false
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_DARK:
            return { ...state, darkMode: action.payload }
        default:
            return state;
    }
}