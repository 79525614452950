import { combineReducers } from 'redux';
import ClientReducer from './ClientReducer';
import EmailReducer from './EmailReducer';
import AlertReducer from './AlertReducer';
import DarkReducer from './DarkReducer';
import PhaseReducer from './PhaseReducer';

const reducers = combineReducers({
    clients: ClientReducer,
    email: EmailReducer,
    alert: AlertReducer,
    dark: DarkReducer,
    phase: PhaseReducer
});

export default reducers;