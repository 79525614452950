import TabNav_ from './TabNav';
import Input_ from './Input';
import Table_ from './Table';
import TableRow_ from './TableRow';
import TableCell_ from './TableCell';
import Template_ from './Template';
import DateInput_ from './DateInput';
import Modal_ from './Modal';
import Dropdown_ from './Dropdown';
import AddPhase_ from './AddPhase';
import EditPhase_ from './EditPhase';

export const EditPhase = EditPhase_;
export const AddPhase = AddPhase_;
export const Dropdown = Dropdown_;
export const Modal = Modal_;
export const DateInput = DateInput_;
export const Template = Template_;
export const TableRow = TableRow_;
export const Table = Table_;
export const Input = Input_;
export const TabNav = TabNav_;
export const TableCell = TableCell_;