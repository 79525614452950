import firebase from 'firebase';
import { FETCH_NOTIFICATIONS, ALERT_PROP_CHANGE } from '../actionTypes/AlertActions';

export const fetchNotifications = () => {
    return async (dispatch) => {
        firebase.firestore().collection('alerts')
        .onSnapshot(snap => {
            dispatch({ type: FETCH_NOTIFICATIONS, payload: snap.docs });
        })
    }
}

export const searchNotifications = (search, notifications) => {
    return async (dispatch) => {
        dispatch({ type: ALERT_PROP_CHANGE, payload: { prop: 'search', value: search }});
        const results = [];
        for (let i = 0; i < notifications.length; i++)  {
            if (notifications[i].data().name.toLowerCase().includes(search.toLowerCase())) {
                results.push(notifications[i]);
            }
        }
        dispatch({ type: ALERT_PROP_CHANGE, payload: { prop: 'results', value: results }});
    }
}