import { FETCH_CLIENTS, SEARCH_CLIENTS, CLIENT_PROP_CHANGE } from '../actionTypes/ClientActions';


const INITIAL_STATE = {
    clients: [],
    search: '',
    results: []
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CLIENTS:
            return { ...state, clients: action.payload }
        case SEARCH_CLIENTS:
            return { ...state, results: action.payload }
        case CLIENT_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value }
        default:
            return state;
    }
}