import React from 'react';
import PropTypes from 'prop-types';
import './styles/Template.css';

const Template = ({ title, children, darkMode }) => {
    return (
        <div className={darkMode ? 'Template-ContainerDark' : 'Template-Container'}>
            <div className={darkMode ? 'Template-HeaderDark' : 'Template-Header'}>
                <h2>{title}</h2>
            </div>
            <div className='Template-Body'>
                {children}
            </div>
        </div>
    )
}

Template.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node
};

export default Template;