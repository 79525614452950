import firebase from 'firebase';
import { FETCH_PHASE_TYPES, PHASE_PROP_CHANGE } from '../actionTypes/PhaseActions';

export const fetchPhaseTypes = () => {
    return async (dispatch) => {
        firebase.firestore().collection('phases').orderBy('order').onSnapshot(snap => {
            const phases = snap.docs;
            const phaseTypes = normalizePhases(phases);
            const phaseHash = createPhaseHash(phases);
            dispatch({ type: FETCH_PHASE_TYPES, payload: { phaseTypes, phaseHash } })
        })
    }
}

export const searchPhases = (search, phaseTypes) => {
    return async (dispatch) => {
        dispatch({ type: PHASE_PROP_CHANGE, payload: { prop: 'search', value: search }});
        const results = [];
        for (let i = 0; i < phaseTypes.length; i++) {
            if (phaseTypes[i].name.toLowerCase().includes(search.toLowerCase())) {
                results.push(phaseTypes[i]);
            }
        }
        dispatch({ type: PHASE_PROP_CHANGE, payload: { prop: 'results', value: results }});
    }
}

const normalizePhases = (phases) => {
    const phaseTypes = [];
    for (let i = 0; i < phases.length; i++) {
        phaseTypes.push(phases[i].data())
    }
    return phaseTypes;
}

const createPhaseHash = (phases) => {
    const phaseHash = {};
    for (let i = 0; i < phases.length; i++) {
        phaseHash[phases[i].data().name] = phases[i].id;
    }
    return phaseHash;
}