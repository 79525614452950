import React from 'react';
import PropTypes from 'prop-types';
import './styles/TableCell.css';

const TableCell  = ({ children, flex }) => {
    return (
        <div style={{ flex }} className='TableCell-Container'>
            {children}
        </div>
    )
}

TableCell.propTypes = {
    children: PropTypes.node
}

export default TableCell;