import { FETCH_NOTIFICATIONS, ALERT_PROP_CHANGE } from "../actionTypes/AlertActions";

const INITIAL_STATE = {
    notifications: [],
    search: '',
    results: []
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS:
            return { ...state, notifications: action.payload };
        case ALERT_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value }
        default:
            return state;
    }
}