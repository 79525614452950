import React from 'react';
import { TextField } from 'material-ui';
import PropTypes from 'prop-types';

const Input = ({ value, onChange, style, fullWidth, floatingLabelText, errorText, type, disabled, darkMode, multiline }) => {

    const change = (event, text) => {
        if (type === 'number') {
            onChange(parseInt(text));
        } else {
            onChange(text);
        }

    }

    return (
        <TextField 
            value={value}
            style={style}
            fullWidth={fullWidth}
            onChange={change}
            floatingLabelText={floatingLabelText}
            floatingLabelFocusStyle={styles.floatLabel}
            floatingLabelStyle={darkMode ? styles.darkFloat : styles.float}
            underlineFocusStyle={styles.underline}
            inputStyle={darkMode && styles.darkInput}
            textareaStyle={darkMode && styles.darkInput}
            multiLine={multiline}
            errorText={errorText}
            type={type}
            disabled={disabled}
        />
    )
}

const styles = {
    floatLabel: {
        color: '#56ACF2'
    },
    underline: {
        borderColor: '#56ACF2'
    },
    error: {
        color: 'salmon'
    },
    darkInput: {
        color: '#fff',
    },
    darkFloat: {
        color: 'rgba(255, 255, 255, 0.7)'
    },
    float: {
        color: 'rgba(0, 0, 0, 0.8)'
    }
}

Input.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    fullWidth: PropTypes.bool,
    floatingLabelText: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool
};

Input.defaultProps = {
    value: '',
    onChange: () => {},
    style: {},
    fullWidth: false,
    type: 'text',
    floatingLabelText: '',
    disabled: false
}

export default Input;