import React, { Component } from 'react';
import './styles/ClientManagement.css';
import { connect } from 'react-redux';
import { ImageEdit } from 'material-ui/svg-icons';
import { FlatButton, IconButton } from 'material-ui';
import { addClient, updateClient, deleteClient, searchClients} from '../../actions/ClientActions';
import { Input, Table, TableRow, TableCell } from '../CommonComponents';
import { ActionDelete } from 'material-ui/svg-icons';
import Template from '../CommonComponents/Template';
import Modal from '../CommonComponents/Modal';
import EmailValidator from 'email-validator';

class ClientManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            updateOpen: false,
            clientName: '',
            email: '',
            clientNameError: false,
            emailError: false,
            badEmailError: false,
            updateOpen: false,
            deleteOpen: false,
            clientId: ''
        }
    }

    actions = [
        <FlatButton 
            label='Save and add another'
            style={{ color: '#56ACF2' }}
            onClick={() => this.saveClientAndAddAnother()}
        />,
        <FlatButton 
            label='Save client'
            style={{ color: '#56ACF2' }}
            onClick={() => this.saveClient()}
        />,
        <FlatButton 
            label='cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelSaveClient()}
        />
    ];

    updateActions = [
        <FlatButton 
            label='update'
            style={{ color: '#56ACF2' }}
            onClick={() => this.updateClient()}
        />,
        <FlatButton 
            label='cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelUpdateClient()}
        />
    ];

    deleteActions = [
        <FlatButton 
            label='delete'
            style={{ color: 'salmon' }}
            onClick={() => this.deleteClient()}
        />,
        <FlatButton 
            label='cancel'
            style={{ color: 'salmon' }}
            onClick={() => this.cancelDelete()}
        />
    ]

    canSave = () => {
        const { email, clientName } = this.state;
        if (!email || !clientName || !EmailValidator.validate(email)) {
            return false;
        }
        return true;
    }

    triggerError = () => {
        if (!this.state.email) {
            this.setState({ emailError: true });
        }
        if (!this.state.clientName) {
            this.setState({ clientNameError: true });
        }
        if (!EmailValidator.validate(this.state.email)) {
            this.setState({ badEmailError: true });
        }
    }

    saveClient = () => {
        if (!this.canSave()) {
            this.triggerError();
        } else {
            this.props.addClient(this.state.clientName, this.state.email);
            this.setState({ clientNameError: false, emailError: false, clientName: '', email: '', badEmailError: false });
            this.toggleDialog();
        }
    }

    updateClient = () => {
        if (!this.canSave()) {
            this.triggerError();
        } else {
            const { email, clientName, clientId } = this.state;
            this.props.updateClient(clientName, email, clientId);
            this.cancelUpdateClient();
        }
    }

    deleteClient = () => {
        this.props.deleteClient(this.state.clientId);
        this.cancelDelete();
    }

    cancelDelete = () => {
        this.setState({
            clientName: '',
            clientId: '',
            deleteOpen: false
        })
    }

    cancelSaveClient = () => {
        this.toggleDialog();
        this.setState({ 
            clientName: '',
            email: '',
            clientNameError: false,
            emailError: false,
        })
    }

    cancelUpdateClient = () => {
        this.setState({
            updateOpen: false,
            clientName: '',
            email: '',
            clientNameError: false,
            emailError: false,
            updateOpen: false,
            clientId: '',
            badEmailError: false
        })
    }

    setClientToUpdate = (clientSnap) => {
        const client = clientSnap.data();
        this.setState({ 
            updateOpen: true,
            clientName: client.name,
            email: client.email,
            clientId: clientSnap.id
        });
    }

    setClientToDelete = (clientSnap) => {
        const client = clientSnap.data();
        this.setState({
            deleteOpen: true,
            clientName: client.name,
            clientId: clientSnap.id
        })
    }

    saveClientAndAddAnother = () => {
        if (!this.canSave()) {
            this.triggerError();
        } else {
            this.props.addClient(this.state.clientName, this.state.email);
            this.setState({ clientName: '', email: '', clientNameError: false, emailError: false });
        }

    }

    clientNameChange = (clientName) => {
        this.setState({ clientName });
    }

    searchClients = (search) => {
        this.props.searchClients(search, this.props.clients)
    }

    emailChange = (email) => {
        this.setState({ email, emailError: false, badEmailError: false });
    }

    toggleDialog = () => {
        this.setState({ open: !this.state.open });
    }

    renderHeader = () => {
        return (
            <div className='ClientManagement-Header'>
                <h1>My Clients</h1>
            </div>
        )
    }

    decideEmailError = () => {
        if (this.state.emailError) {
            return 'Email is required.';
        }
        if (this.state.badEmailError) {
            return 'Please use a valid email.'
        }
    }

    renderDialog = () => {
        return (
            <Modal 
                open={this.state.open}
                title='Create a new client'
                actions={this.actions}
                darkMode={this.props.darkMode}
            >
                <Input 
                    floatingLabelText='Client Name'
                    fullWidth
                    value={this.state.clientName}
                    onChange={this.clientNameChange}
                    darkMode={this.props.darkMode}
                    errorText={this.state.clientNameError && 'Client Name is required'}
                />
                <Input 
                    floatingLabelText='Email'
                    fullWidth
                    value={this.state.email}
                    darkMode={this.props.darkMode}
                    onChange={this.emailChange}
                    errorText={this.decideEmailError()}
                />
            </Modal>
        )
    }

    renderUpdateDialog = () => {
        return (
            <Modal 
                open={this.state.updateOpen}
                title={`Update ${this.state.clientName} Email`}
                actions={this.updateActions}
                darkMode={this.props.darkMode}
            >
                <Input 
                    floatingLabelText='Email'
                    fullWidth
                    value={this.state.email}
                    onChange={this.emailChange}
                    darkMode={this.props.darkMode}
                    errorText={this.decideEmailError()}
                />
            </Modal>
        )
    }

    renderDeleteDialog = () => {
        return (
            <Modal 
                open={this.state.deleteOpen}
                title={`Are you sure you want to delete ${this.state.clientName}?`}
                actions={this.deleteActions}
                darkMode={this.props.darkMode}
            />
        )
    }

    renderTable = () => {
        return (
            <Table 
                placeholder='Search by Client Name...'
                search={this.props.search}
                onChange={this.searchClients}
                darkMode={this.props.darkMode}
                add={this.toggleDialog}
                headers={['Client Name', 'Email Address', 'Update', 'Delete']}
                showEmpty={this.props.clients.length === 0}
                showInfo={this.props.clients.length > 0}
                infoLabel={this.createInfoLabel()}
                emptyMessage="You don't have any clients yet."
                addLabel='Add Client'
            >
                {this.renderClients()}
            </Table>
        )
    }

    createInfoLabel = () => {
        let label = '';
        if (this.props.clients.length === 1) {
            label = 'Client';
        }
        if (this.props.clients.length > 1) {
            label = 'Clients'
        }
        return `${this.props.clients.length} ${label}`;
    }

    renderClient = (clientSnap, index) => {
        return (
            <TableRow darkMode={this.props.darkMode} index={index} key={index}>
                <TableCell>
                    <p>{clientSnap.data().name}</p>
                </TableCell>
                <TableCell>
                    <p>{clientSnap.data().email}</p>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.setClientToUpdate(clientSnap)}>
                        <ImageEdit color='#56ACF2' />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => this.setClientToDelete(clientSnap)}>
                        <ActionDelete color='salmon' />
                    </IconButton>

                </TableCell>
            </TableRow>
        )
    }

    renderClients = () => {
        const { clients, results } = this.props;
        const list = results.length > 0 ? results : clients;
        return list.map(this.renderClient);
    }

    render = () => {
        return (
            <Template darkMode={this.props.darkMode} title='My Clients'>
                {this.renderTable()}
                {this.renderDialog()}
                {this.renderUpdateDialog()}
                {this.renderDeleteDialog()}
            </Template>
        )
    }
}

const styles = {
    searchIcon: { 
        width: 30, 
        height: 30, 
        marginLeft: 15,
        marginRight: 15,
    },
    dialogContent: {
        width: '35vw'
    },
    title: {
        backgroundColor: '#50473A', 
        color: '#fff'
    }
}

const mapStateToProps = (state) => {
    const { clients, search, results } = state.clients;
    const { darkMode } = state.dark;
    return { clients, search, results, darkMode }
}

export default connect(mapStateToProps, { 
    addClient, 
    updateClient, 
    deleteClient, 
    searchClients 
})(ClientManagement);